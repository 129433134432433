/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '../../@core/scss/base/components/include'; // Components includes
.main-menu{
	.navbar-header{
	padding: 0;
	.logosm {
	margin: 10px;
	}
	.bus-hunter-logo{
		margin: 3px 10px 10px 28px;
	}
	}
	.navbar-brand
	{
		margin: 10px 20px 0 0 !important;
	}
	.nav-toggle{
		margin: 10px 10px 10px 10px;
	}
}
.setZindex {
	z-index: 5 !important;
}
.ms2{
	margin: 0 !important;
}
.dark-layout {
	background-color: #161d31 !important;
	// .rdt_TableRow,.rdt_TableBody {
	// 	background-color: $theme-dark-card-bg !important;
	// 	color: white;
	// }
	// .rdt_TableHeadRow {
	// 	background-color: $theme-dark-card-bg !important;
	// 	color: white;
	// }
	// .rdt_Table :hover {
	// 	background: red;
	// }
}
.dark-layout body {
	background:  $theme-dark-card-bg !important;
}
.controlDatatableHeight > :first-child {
	min-height: 70vh;
}  
.controlDatatableHeight1 > :first-child {
	min-height: 60vh;
}  
.fitContent{
	max-width: fit-content;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
	background-color: #7367f0;
	color: white;
	padding: 16px 20px;
	border: none;
	cursor: pointer;
	opacity: 0.8;
	position: fixed;
	bottom: 23px;
	right: 28px;
	/* width: 280px; */
	border-radius: 50%;
}

#form-id {
	min-width: 420px;
	max-width:420px;
	height:80vh;
	// background-color : white;
	overflow-y : auto;
	display : flex;
	flex-direction : column
}
@media (max-width: 767px) {
	#form-id {
	  min-width: unset;
	  max-width: unset;
	  width: 100%;
	  height: 80vh;
	}
  }
  /* The popup chat - hidden by default */
.chat-popup {
	display: none;
	position: fixed;
	bottom: 0;
	right: 15px;
	// border: 3px solid #f1f1f1;
	z-index: 9;
	box-shadow: 2px 10px #f6f6f6;
}
.chat-button-container {
	// background-color: #283046;
	width: 100%;
}
/* Add styles to the form container */
// .form-container {
// 	max-width: 420px;
// 	min-width: 420px;
// 	/* padding: 10px; */
// 	background-color: white;
// 	overflow-y: scroll;
// 	/* overflow-x:hidden; */
// 	height: 600px;
// }
  
/* Full-width textarea */
.form-container textarea {
width: 100%;
padding: 15px;
margin: 5px 0 22px 0;
border: none;
// background: #f1f1f1;
resize: none;
/* min-height: 200px; */
}
  /* When the textarea gets focus, do something */
.form-container textarea:focus {
background-color: #ddd;
outline: none;
}
// change color of buttons
#cursorPointer:hover
{
    color: #7367f0;
	cursor: pointer;
}
.activeGPTTab {
	border-bottom: 2px solid #7367f0;
	padding-bottom: 10px;
}
// .navbar-header:hover{
// 	width: 260px;
// }
